<div ng-if="!hidden" class="mailing-list-settings">
  <div class="mailing-list-settings-buttons">
    <button type="button" md-button (click)="sendMail()">Send Email</button>
    <button type="button" md-button (click)="export()">Export to CSV</button>
  </div>

  <div class="mailing-list-settings-buttons">
    <md-slide-toggle color="primary" [checked]="moduleSetting" (change)="changeModuleSetting()">
      Display subscribe button
    </md-slide-toggle>
  </div>


  <button type="button" md-button (click)="deleteCookie()">Delete Cookie</button>
</div>
