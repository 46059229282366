<form name="mailingListForm">
  <h1 md-dialog-title>Mailing List</h1>
  
  <div md-dialog-content>
    <p>Please enter your email address to subscribe to our mailing list.</p>
    <md-form-field class="full-width">
      <input type="email" [formControl]="emailFormControl" mdInput placeholder="Email address" required>
      <md-error *ngIf="emailFormControl.hasError('pattern')">
        Please enter a valid email address
      </md-error>
    </md-form-field>
  </div>

  <div md-dialog-actions>
    <button type="button" md-button [disabled]="emailFormControl.invalid" (click)="subscribe()" color="primary">
      Subscribe
    </button>
    <button type="button" md-button (click)="dialogRef.close()">
      No Thanks
    </button>
  </div>
</form>
